const LoadingState = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-screen">
      <div className="animate-bounce">
        <img src="/images/logo/logo-green-no-bg.svg" alt="loading logo" />
      </div>
      <p className="text-display-xs text-black-400">Loading</p>
    </div>
  )
}

export default LoadingState
