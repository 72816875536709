import { Link } from "react-router-dom"

const DrawerMenu = ({ drawerOpened, url, icon, title, active }) => {
  return (
    <Link to={ url } title={ title } className={ `flex items-center gap-x-4 w-full p-3 rounded-10 cursor-pointer ${active ? 'bg-primary-600 text-neutral-100' : 'bg-neutral-100 text-primary-600 hover:bg-primary-200'} active:bg-primary-600  active:text-neutral-100 text-sm font-medium` }>
      { icon }
      <p className={ !drawerOpened ? 'hidden' : '' }>{ title }</p>
    </Link>
  )
}

export default DrawerMenu
