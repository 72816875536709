import React from "react";

export const RegisterPage = React.lazy(() => import("./register-page/RegisterPage"))
export const LoginPage = React.lazy(() => import("./login-page/LoginPage"))
export const ForgotPasswordPage = React.lazy(() => import("./forgot-password-page/ForgotPasswordPage"))
export const ResetPasswordPage = React.lazy(() => import("./reset-password-page/ResetPasswordPage"))
export const NotFoundPage = React.lazy(() => import("./not-found-page/NotFoundPage"))
export const DashboardPage = React.lazy(() => import("./dashboard-page/DashboardPage"))
export const KaryawanPage = React.lazy(() => import("./karyawan-page/KaryawanPage"))
export const ProfilePage = React.lazy(() => import("./profile-page/ProfilePage"))
export const DetailAktivitasPage = React.lazy(() => import("./aktivitas-page/DetailAktivitasPage"))
export const AktivitasPage = React.lazy(() => import("./aktivitas-page/AktivitasPage"))
export const ClientPage = React.lazy(() => import("./client/client-page/ClientPage"))
export const DetailClientPage = React.lazy(() => import("./client/detail-client-page/DetailClientPage"))
export const CreateClientPage = React.lazy(() => import("./client/create-client-page/CreateClientPage"))
export const PembukaanClientPage = React.lazy(() => import("./client/pembukaan-client-page/PembukaanClientPage"))
export const KategoriClientPage = React.lazy(() => import("./client/kategori-client-page/KategoriClientPage"))
export const JadwalPage = React.lazy(() => import("./jadwal-page/JadwalPage"))
export const AnalisaKegiatanPage = React.lazy(() => import("./analisa-kegiatan/analisa-kegiatan-page/AnalisaKegiatanPage"))
export const DetailAnalisaKegiatanPage = React.lazy(() => import("./analisa-kegiatan/detail-analisa-kegiatan-page/DetailAnalisaKegiatanPage"))
