import { Outlet } from "react-router-dom"

const OnboardLayout = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex bg-neutral-100 w-6/12 h-[580px] rounded-[30px] overflow-hidden shadow-md">
        <div className="flex justify-center items-center w-5/12 bg-primary-600">
          <img src="/images/logo/logo-white-no-bg.svg" alt="Logo" />
        </div>
        <div className="flex-1 flex flex-col gap-6 justify-center py-16 px-10">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default OnboardLayout
