import { useState } from "react"
import { MdKeyboardArrowDown } from "react-icons/md"
import { Link } from "react-router-dom"

const DrawerMenuDropdown = ({ drawerOpened, openDrawer, title, active, listMenu, children }) => {
  const [dropdownOpened, setDropdownOpened] = useState(false)

  const toggleDropdownHandler = () => {
    if (!drawerOpened && dropdownOpened) return openDrawer()
    if (!drawerOpened) openDrawer()
    setDropdownOpened(!dropdownOpened)
  }

  const DrawerDropdownList = ({ url, name, active }) => {
    return (
      <li>
        <Link to={ url } className={ `block py-2 px-4 text-sm font-medium ${active ? 'text-primary-600' : 'text-black-300'} hover:text-primary-600` }>{ name }</Link>
      </li>
    )
  }

  return (
    <div>
      <button
        className={ `flex justify-between items-center w-full p-3 rounded-10 cursor-pointer ${active ? 'bg-primary-600 text-neutral-100' : 'bg-neutral-100 text-primary-600 hover:bg-primary-200'} active:bg-primary-600 active:text-neutral-100 text-sm font-medium` }
        onClick={ toggleDropdownHandler }
        title={ title }>
        <div className='flex gap-x-4 items-center'>
          { children }
        </div>
        <MdKeyboardArrowDown className={ `w-6 h-6 ${dropdownOpened && 'rotate-180'} duration-200` } />
      </button>
      <div className={ `relative ${(!drawerOpened || !dropdownOpened) && 'hidden'} ml-3 duration-200` }>
        <ul>
          { listMenu.map((list, i) => <DrawerDropdownList key={ i } { ...list } />) }
        </ul>
      </div>
    </div>
  )
}

export default DrawerMenuDropdown
