import React, { Suspense, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminLayout, LoadingState, OnboardLayout } from "./components/layout";
import AuthContext from "./context/auth-context";
import { AktivitasPage, AnalisaKegiatanPage, ClientPage, CreateClientPage, DashboardPage, DetailAktivitasPage, DetailAnalisaKegiatanPage, DetailClientPage, ForgotPasswordPage, JadwalPage, KaryawanPage, KategoriClientPage, LoginPage, NotFoundPage, PembukaanClientPage, ProfilePage, RegisterPage, ResetPasswordPage } from "./pages";

function App() {
  const authCtx = useContext(AuthContext)

  return (
    <Suspense fallback={ <LoadingState /> }>
      <Routes>
        <Route element={ <OnboardLayout /> }>
          <Route path="/" element={ <LoginPage /> } />
          <Route path="/register" element={ <RegisterPage /> } />
          <Route path="/forgot-password" element={ <ForgotPasswordPage /> } />
          <Route path="/reset-password" element={ <ResetPasswordPage /> } />
        </Route>
        <Route element={ !authCtx.isLoggedIn ? <Navigate to='/' /> : <AdminLayout /> }>
          <Route path="/dashboard" element={ <DashboardPage /> } />
          <Route path="/karyawan" element={ <KaryawanPage /> } />
          <Route path="/karyawan/:id" element={ <ProfilePage /> } />
          <Route path="/karyawan/:id/aktivitas" element={ <DetailAktivitasPage /> } />
          <Route path="/karyawan/:id/aktivitas/analisa" element={ <DetailAnalisaKegiatanPage /> } />
          <Route path="/aktivitas" element={ <AktivitasPage /> } />
          <Route path="/aktivitas/analisa" element={ <AnalisaKegiatanPage /> } />
          <Route path="/client" element={ <ClientPage /> } />
          <Route path="/client/:id" element={ <DetailClientPage /> } />
          <Route path="/client/:id/edit" element={ <CreateClientPage /> } />
          <Route path="/client/create" element={ <CreateClientPage /> } />
          <Route path="/client/pembukaan" element={ <PembukaanClientPage /> } />
          <Route path="/client/kategori" element={ <KategoriClientPage /> } />
          <Route path="/jadwal" element={ <JadwalPage /> } />
          <Route path="/profile" element={ <ProfilePage /> } />
        </Route>
        <Route path="*" element={ <NotFoundPage /> } />
      </Routes>
    </Suspense>
  )
}

export default App;
