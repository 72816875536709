import { MdCalendarToday, MdOutlineDashboard, MdOutlineHomeWork, MdOutlineMap, MdPersonOutline } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import DrawerMenu from './DrawerMenu'
import DrawerMenuClient from './DrawerMenuClient'

const Drawer = ({ isOpen, openDrawer }) => {
  const pathLocation = useLocation().pathname

  return (
    <div className={ `${isOpen ? 'w-60' : 'w-16'} min-h-screen max-h-full px-2 bg-neutral-100` }>
      <div className="sticky top-3 flex flex-col mt-3 gap-2">
        <div className={ `flex gap-x-4 items-center mb-3 ${!isOpen ? 'justify-center' : ''}` }>
          <img src="/images/logo/logo-white-bg-green.svg" alt="app logo" className={ `${isOpen && 'ml-1'} rounded-10 w-10` } />
          <h1 className={ `${!isOpen ? 'hidden' : ''} text-lg font-medium text-primary-600` }>Sentry</h1>
        </div>

        <DrawerMenu
          drawerOpened={ isOpen }
          url='/dashboard'
          icon={ <MdOutlineDashboard className="w-6 h-6" /> }
          title='Dashboard'
          active={ pathLocation === '/dashboard' }
        />

        <DrawerMenu
          drawerOpened={ isOpen }
          url='/karyawan'
          icon={ <MdPersonOutline className="w-6 h-6" /> }
          title='Karyawan'
          active={ pathLocation === '/karyawan' }
        />

        <DrawerMenu
          drawerOpened={ isOpen }
          url='/aktivitas'
          icon={ <MdOutlineMap className="w-6 h-6" /> }
          title='Aktivitas'
          active={ pathLocation === '/aktivitas' }
        />

        <DrawerMenuClient drawerOpened={ isOpen } openDrawer={ openDrawer } />

        <DrawerMenu
          drawerOpened={ isOpen }
          url='/jadwal'
          icon={ <MdCalendarToday className="w-6 h-6" /> }
          title='Jadwal'
          active={ pathLocation === '/jadwal' }
        />

        <DrawerMenu
          drawerOpened={ isOpen }
          url='/aktivitas/analisa'
          icon={ <MdOutlineHomeWork className="w-6 h-6" /> }
          title='Analisa kegiatan'
          active={ pathLocation === '/aktivitas/analisa' }
        />
      </div>
    </div>
  )
}

export default Drawer
