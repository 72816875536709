import { MdOutlineStorefront } from "react-icons/md"
import { useLocation } from "react-router-dom"
import DrawerMenuDropdown from "./DrawerMenuDropdown"

const clientListMenu = [
  { url: '/client', name: 'Data Client', active: false },
  { url: '/client/pembukaan', name: 'Pembukaan Client', active: false },
  { url: '/client/kategori', name: 'Kategori', active: false },
]

const DrawerMenuClient = ({ drawerOpened, openDrawer }) => {
  const pathLocation = useLocation().pathname
  if (pathLocation.includes('/client')) {
    clientListMenu.forEach(menu => {
      if (menu.url === pathLocation) menu.active = true
      else menu.active = false
    })
  } else {
    clientListMenu.forEach(menu => menu.active = false)
  }

  return (
    <DrawerMenuDropdown drawerOpened={ drawerOpened } openDrawer={ openDrawer } listMenu={ clientListMenu } title='Client' active={ pathLocation.includes('/client') }>
      <MdOutlineStorefront className="w-6 h-6" />
      <p className={ !drawerOpened ? 'hidden' : '' }>Client</p>
    </DrawerMenuDropdown>
  )
}

export default DrawerMenuClient
