import React, { useState } from "react"

const AuthContext = React.createContext({
  isLoggedIn: false,
  loginToken: '',
  loggedInUserId: '',
  expiredLogin: '',
  login: ({ token, idKaryawan, expiredAt }) => { },
  logout: () => { },
})

export const AuthContextProvider = ({ children }) => {
  const initialToken = localStorage.getItem('token')
  const initialUserId = localStorage.getItem('uid')
  const initialExpLogin = localStorage.getItem('tokenExp')

  const [loginToken, setLoginToken] = useState(initialToken)
  const [loggedInUserId, setLoggedInUserId] = useState(initialUserId)
  const [expiredLogin, setLoginExpired] = useState(initialExpLogin)

  const userIsLoggedIn = !!loginToken

  const loginHandler = ({ token, idKaryawan, expiredAt }) => {
    setLoginToken(token)
    setLoggedInUserId(idKaryawan)
    setLoginExpired(expiredAt)

    localStorage.setItem('token', token)
    localStorage.setItem('uid', idKaryawan)
    localStorage.setItem('tokenExp', expiredAt)
  }

  const logoutHandler = () => {
    setLoginToken(null)
    localStorage.removeItem('token')
    localStorage.removeItem('uid')
    localStorage.removeItem('tokenExp')
  }

  const contextValue = {
    isLoggedIn: userIsLoggedIn,
    loginToken,
    loggedInUserId,
    login: loginHandler,
    logout: logoutHandler
  }

  return (
    <AuthContext.Provider value={ contextValue }>
      { children }
    </AuthContext.Provider>
  )
}

export default AuthContext
