import { useState } from "react"
import { Outlet } from "react-router-dom"
import { Drawer } from "./drawer"
import { Navbar } from "./navbar"

const AdminLayout = () => {
  const [drawerIsOpend, setDrawerIsOpend] = useState(false)

  const toggleDrawer = () => setDrawerIsOpend(!drawerIsOpend)
  const openDrawer = () => setDrawerIsOpend(true)

  return (
    <div className="flex">
      <Drawer isOpen={ drawerIsOpend } openDrawer={ openDrawer } />
      <div className="w-full">
        <Navbar toggleDrawer={ toggleDrawer } />
        <div className="xl:container mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AdminLayout
